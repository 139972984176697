export const connections_confg = {
    // LOCAL
    // baseURLManagement: 'https://localhost:7190',
    // baseURLProduction: 'https://localhost:7199',
    // baseURLSettlement: 'https://localhost:7192',

    // baseURLManagement: 'https://dev-management.lucy.work',
    // baseURLProduction: 'https://dev-production.lucy.work',
    //baseURLSettlement: 'https://dev-settlement.lucy.work',

    // //PUBLICACION QA
    //baseURLManagement: 'https://qa-management.lucy.work',
    //baseURLProduction: 'https://qa-production.lucy.work',
    //baseURLSettlement: 'https://qa-settlement.lucy.work',

    // PUBLICACION MAO
    // baseURLManagement: 'https://siacmaomana-api.godoycordoba.com',
    // baseURLProduction: 'https://siacmaoprod-api.godoycordoba.com',
    // baseURLSettlement: 'https://siacmaoselt-api.godoycordoba.com',

    // //PUBLICACION QA integracion continua
    baseURLManagement: 'https://api-qa.lucy.work/management',
    baseURLProduction: 'https://api-qa.lucy.work/production',
    baseURLSettlement: 'https://api-qa.lucy.work/settlement',

    // PUBLICACION PROD integracion continua
    // baseURLManagement: 'https://apiv2.lucy.work/management',
    // baseURLProduction: 'https://apiv2.lucy.work/production',
    // baseURLSettlement: 'https://apiv2.lucy.work/settlement',
};
