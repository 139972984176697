export default {
    // LOCAL
    // domain: 'auth0.godoycordoba.com',
    // clientId: 'iFiCj6YyAC2vtN4QFdI6oZH1BqmgoxPl',
    // useRefreshTokens: true,
    // audience: 'https://localhost:7190',
    // serverUrl: 'https://localhost:7190',

    // PUBLICACION QA integracion continua
     domain: 'lucywork.us.auth0.com',
     clientId: 'D9Jjo8PtnnwmBBuuEtin5UgpSDsOI7eM',
     useRefreshTokens: true,
     audience: 'https://api-qa.lucy.work',
     serverUrl: 'https://api-qa.lucy.work',

    // PUBLICACION QA
    // domain: 'godoycordoba.us.auth0.com',
    // clientId: 'Y7UiNYWdX073CNjmd1WkIqMvSBpTl0kM',
    // useRefreshTokens: true,
    // audience: 'https://talentoqa-api.godoycordoba.com',
    // serverUrl: 'https://talentoqa-api.godoycordoba.com',

    // PUBLICACION MAO
    // domain: 'godoycordoba.us.auth0.com',
    // clientId: 'cgHsJPHdEVTndXo8Z4lVZO1HthiSGbVo',
    // useRefreshTokens: true,
    // audience: 'https://talentoqa-api.godoycordoba.com',
    // serverUrl: 'https://talentoqa-api.godoycordoba.com',

    //PUBLICACION LUCY PROD
    // domain: 'lucywork.us.auth0.com',
    // clientId: '5ei3WuhzqUpdwSO8pUru9di7Pf3BHTLz',
    // useRefreshTokens: true,
    // audience: 'https://lucy-management.lucy.work',
    // serverUrl: 'https://lucy-management.lucy.work',

    //PUBLICACION PRODuccion integracion
    //domain: 'lucywork.us.auth0.com',
    //clientId: '5ei3WuhzqUpdwSO8pUru9di7Pf3BHTLz',
    //useRefreshTokens: true,
    //audience: 'https://apiv2.lucy.work',
    //serverUrl: 'https://apiv2.lucy.work',
};
